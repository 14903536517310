<template>
  <div>
    <div class="header">
      <van-nav-bar
        title="提现记录详情"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<van-row>
			<table id="tabHold">
				<tbody>
					<tr>
						<td class="tFirst">提现金额:</td>
						<td class="tSecond">{{datainfo.withdraw_num || '0'}} </td>
					</tr>
					<tr>
						<td class="tFirst">状态:</td>
						<td class="tSecond">
							<span v-if="datainfo.status === '0'">
							  {{ $t('dai-shen-he') }} </span>
							<span v-if="datainfo.status === '1'">
							  {{ $t('tong-guo') }} </span>
							 <span v-if="datainfo.status === '2'">
							  {{ $t('bo-hui') }} </span>
						</td>
					</tr>
					<tr>
						<td class="tFirst">申请时间:</td>
						<td class="tSecond" v-if="datainfo.createtime">{{ datainfo.createtime | dateformat }}</td>
					</tr>
					<tr>
						<td class="tFirst">系统备注:</td>
						<td class="tSecond">{{datainfo.remark || ''}}</td>
					</tr>
				</tbody>
			</table>
		</van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datainfo:{}
    }
  },
  created() {
	  if(this.$route.params.id){
		this.id=this.$route.params.id;
		this.getOrders();
	  }
  },
  mounted() {},
  methods: {
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/trade/withdrawsinfo/id/' + this.id)
      if (data) {
        if (data.code === 200) {
          this.datainfo = data.data
        }
      }
    },
  }
}
</script>
<style lang="less" scoped>
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	#tabHold{
		border-collapse: collapse;
		width: calc(100% - 0.426667rem);
		margin: 0.213333rem;
		border-color: gray;
	}
	#tabHold tbody {
	    color: #8686aa;
		    border-color: gray;
	}
	#tabHold tr {
	    text-align: center;
	    border-top: 0.026667rem solid #8686aa;
	    border-bottom: 0.026667rem solid #8686aa;
	}
	#tabHold td{
		height: 3.066667rem;
		border-right: 0.026667rem solid #8686aa;
	}
	.tFirst {
	    width: 100px;
	    font-size: 16px;
		text-align: right;
		vertical-align: middle;
		padding-right: 0.5rem;
	}
	.tSecond{
		text-align: left;
		vertical-align: middle;
		padding-left: 0.5rem;
		height: 3.066667rem;
		border-right: none !important;
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    color: #8686aa;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    border-bottom: 0.026667rem solid #23293f;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
